import React, {useEffect, useState} from 'react'
import {driveInitValues as initialValues, IDriveDetails} from './DriveModel';
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    createNewDrive,
    createNewDriveGroup,
    deleteDriveById,
    editDriveById,
    editDriveGroupById,
    getLeadingForDrive
} from "./DriveFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {createComponentItemImg} from "../../componentCreate/ComponentsCreateFunctions";
import {changePosition} from "../../../component/customChangePosition/customChangePosition";

const leadingSchema = Yup.object().shape({
    name: Yup.string().required('Pole "Nazwa" jest wymagane').nullable(),
    ktm: Yup.string().required('Pole "Ktm" jest wymagane').nullable(),
})

const DriveCreateForm: React.FC<{
    chosenListItem: IDriveDetails,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = (props) => {
    const {setChosenListItem, chosenListItem, setOpen, drivesGroups} = props;
    const client = useQueryClient();

    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [initValues, setInitValues] = useState<{}>(initialValues);
    const [showImg, setShowImg] = useState<{}>(true);
    const [showRender, setShowRender] = useState<{}>(true);
    const {data: leading} = useQuery("leading", () => getLeadingForDrive(accessToken))

    useEffect(() => {
        if (chosenListItem) {
            setInitValues(
                {
                    ...chosenListItem,
                    ktm: chosenListItem?.drives[0]?.ktm,
                    ktmDescription: chosenListItem?.ktmDescription,
                    price: chosenListItem?.price / 100,
                    driveFormula: chosenListItem?.driveFormula,
                    translations: {
                        en: {
                            name: chosenListItem?.drives[0]?.translations?.en?.name
                        },
                        ru: {
                            name: chosenListItem?.drives[0]?.translations?.ru?.name
                        },
                        de: {
                            name: chosenListItem?.drives[0]?.translations?.de?.name
                        },
                        fr: {
                            name: chosenListItem?.drives[0]?.translations?.fr?.name
                        }
                    },
                    details: chosenListItem.drives.length > 0 ? chosenListItem.drives.map(drive => {
                        return {
                            id: drive.id,
                            minHeight: drive.minHeight,
                            maxHeight: drive.maxHeight,
                            minTotalHeight: drive.minTotalHeight,
                            maxTotalHeight: drive.maxTotalHeight,
                            maximalSurface: drive?.maximalSurface,
                            leftArea: drive.leftArea,
                            rightArea: drive.rightArea,
                            depth: drive.depth,
                            leadings: drive.leadings.map(l => l.id),
                            ktm: drive.ktm,
                            ktmDescription: drive.ktmDescription
                        }
                    }) : [{
                        minHeight: 0,
                        maxHeight: 0,
                        minTotalHeight: 0,
                        maxTotalHeight: 0,
                        maximalSurface: 0,
                        leftArea: 0,
                        rightArea: 0,
                        translations: {
                            en: {
                                name: chosenListItem?.drives[0]?.translations?.en?.name
                            },
                            ru: {
                                name: chosenListItem?.drives[0]?.translations?.ru?.name
                            },
                            de: {
                                name: chosenListItem?.drives[0]?.translations?.de?.name
                            },
                            fr: {
                                name: chosenListItem?.drives[0]?.translations?.fr?.name
                            }
                        },
                        depth: 0,
                        ktm: "",
                        ktmDescription: "",
                        leading: []
                    }]
                })
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])

    const driveImgMutation = useMutation(createComponentItemImg, {
        onSuccess: () => {
            client.invalidateQueries('drives')
            client.invalidateQueries('drive_groups')
        }
    })

    const editDriveMutation = useMutation(editDriveById, {
        meta: {
            isEditDriveMutation: true
        },
        onSuccess: () => {
            client.invalidateQueries('drives')
            client.invalidateQueries('drive_groups')
        }
    })
    const editDriveGroupMutation = useMutation(editDriveGroupById, {
        meta: {
            isEditDriveGroupMutation: true
        },
        onSuccess: () => {
            client.invalidateQueries('drive_groups')
            client.invalidateQueries('drives')
        }
    })

    const createDriveMutation = useMutation(createNewDrive, {
        onSuccess: () => {
            client.invalidateQueries('drives')
            client.invalidateQueries('drive_groups')
        }
    })

    const createDriveGroupMutation = useMutation(createNewDriveGroup, {
        onSuccess: () => {
            client.invalidateQueries('drive_groups')
            client.invalidateQueries('drives')
        }
    })

    const changePositionMutation = useMutation(changePosition)
    const deleteSingleDrive = useMutation(deleteDriveById)
    return (
        <>
            <div className='text-center mb-13'>
                <h1 className='mb-3'>{(chosenListItem || editDriveGroupMutation.isLoading || editDriveMutation.isLoading) || (editDriveGroupMutation.isSuccess || editDriveMutation.isSuccess) ? "Edytuj" : "Dodaj"} napęd</h1>
            </div>
            <div className='card mb-5 mb-xl-10'>
                <Formik
                    initialValues={initValues}
                    validationSchema={leadingSchema}
                    enableReinitialize={true}
                    onSubmit={(values, {resetForm}) => {
                        const options = {
                            onSuccess: () => {
                                setTimeout(() => {
                                    setOpen(prev => !prev)
                                }, 2000)
                            },
                            onError: (e) => {
                                console.log(e)
                            }
                        }
                        if (chosenListItem && chosenListItem.id) {
                            driveImgMutation.mutate({
                                accessToken,
                                data: values.file,
                                render: null
                            }, {
                                onSuccess: (data) => {
                                    driveImgMutation.mutate({
                                        accessToken,
                                        data: null,
                                        render: values.render
                                    }, {
                                        onSuccess: (render) => {
                                            editDriveGroupMutation.mutate({
                                                accessToken,
                                                data: {
                                                    name: values.name,
                                                    price: values.price * 100,
                                                    image: data?.id,
                                                    render: render?.id,
                                                    driveFormula: values.driveFormula,
                                                },
                                                driveId: chosenListItem.id
                                            }, {
                                                onSuccess: (gateGroup) => {
                                                    values.details.forEach((detail, index) => {
                                                        if (detail?.id) {
                                                            editDriveMutation.mutate({
                                                                accessToken,
                                                                data: {
                                                                    driveGroup: gateGroup.id,
                                                                    name: values.name,
                                                                    minHeight: +values.details[index].minHeight,
                                                                    maxHeight: +values.details[index].maxHeight,
                                                                    minTotalHeight: +values.details[index].minTotalHeight,
                                                                    maxTotalHeight: +values.details[index].maxTotalHeight,
                                                                    depth: +values.details[index].depth,
                                                                    leftArea: +values.details[index].leftArea,
                                                                    rightArea: +values.details[index].rightArea,
                                                                    leadings: values?.details[index]?.leadings?.map(i => i.split('/').reverse()[0]),
                                                                    image: data?.id,
                                                                    maximalSurface: values?.drives[0]?.maximalSurface > 0 ? values?.drives[0]?.maximalSurface : 1,
                                                                    render: render?.id,
                                                                    ktm: values?.ktm,
                                                                    ktmDescription: values?.drives[0]?.ktmDescription,
                                                                    translations: {
                                                                        en: {
                                                                            name: values.translations?.en?.name
                                                                        },
                                                                        ru: {
                                                                            name: values.translations?.ru?.name
                                                                        },
                                                                        de: {
                                                                            name: values.translations?.de?.name
                                                                        },
                                                                        fr: {
                                                                            name: values.translations?.fr?.name
                                                                        }
                                                                    },
                                                                },
                                                                driveId: detail.id
                                                            }, options)
                                                        } else {
                                                            createDriveMutation.mutate({
                                                                accessToken,
                                                                data: {
                                                                    driveGroup: gateGroup.id,
                                                                    name: values.name,
                                                                    minHeight: +values.details[index].minHeight,
                                                                    maxHeight: +values.details[index].maxHeight,
                                                                    minTotalHeight: +values.details[index].minTotalHeight,
                                                                    maxTotalHeight: +values.details[index].maxTotalHeight,
                                                                    depth: +values.details[index].depth,
                                                                    leftArea: +values.details[index].leftArea,
                                                                    rightArea: +values.details[index].rightArea,
                                                                    maximalSurface: values?.drives[0]?.maximalSurface > 0 ? values?.drives[0]?.maximalSurface : 1,
                                                                    ktm: values?.ktm,
                                                                    ktmDescription: values?.drives[0]?.ktmDescription,
                                                                    leadings: values?.details[index]?.leadings?.map(i => i.split('/').reverse()[0]),
                                                                    image: data?.id,
                                                                    render: render?.id,
                                                                    translations: {
                                                                        en: {
                                                                            name: values.translations?.en?.name
                                                                        },
                                                                        ru: {
                                                                            name: values.translations?.ru?.name
                                                                        },
                                                                        de: {
                                                                            name: values.translations?.de?.name
                                                                        },
                                                                        fr: {
                                                                            name: values.translations?.fr?.name
                                                                        }
                                                                    },
                                                                }
                                                            }, options)
                                                        }

                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        } else {
                            driveImgMutation.mutate({
                                accessToken,
                                data: values.file,
                                render: null
                            }, {
                                onSuccess: (data) => {
                                    driveImgMutation.mutate({
                                        accessToken,
                                        data: null,
                                        render: values.render
                                    }, {
                                        onSuccess: (render) => {
                                            createDriveGroupMutation.mutate({
                                                accessToken,
                                                data: {
                                                    name: values.name,
                                                    price: values.price * 100,
                                                    image: data?.id,
                                                    render: render?.id,
                                                    driveFormula: values.driveFormula
                                                }
                                            }, {
                                                onSuccess: (gateGroup) => {
                                                    changePositionMutation.mutate({
                                                        url: "drive_groups",
                                                        id: gateGroup.id,
                                                        data: {
                                                            driveGroupOrder: drivesGroups?.length + 1
                                                        },
                                                        accessToken
                                                    }, {
                                                        onSuccess: () => {
                                                            client.invalidateQueries('drive_groups')
                                                        }
                                                    })
                                                    if (values.details.length === 0) {
                                                        createDriveMutation.mutate({
                                                            accessToken,
                                                            data: {
                                                                driveGroup: gateGroup.id,
                                                                name: values.name,
                                                                minHeight: 99999999,
                                                                maxHeight: 99999999,
                                                                minTotalHeight: 99999999,
                                                                maxTotalHeight: 99999999,
                                                                depth: 99999999,
                                                                leftArea: 99999999,
                                                                rightArea: 99999999,
                                                                ktm: values?.ktm,
                                                                maximalSurface: values?.drives[0]?.maximalSurface > 0 ? values?.drives[0]?.maximalSurface : 1,
                                                                ktmDescription: values?.drives[0]?.ktmDescription,
                                                                leadings: [],
                                                                image: data?.id,
                                                                render: render?.id,
                                                                translations: {
                                                                    en: {
                                                                        name: values.translations?.en?.name
                                                                    },
                                                                    ru: {
                                                                        name: values.translations?.ru?.name
                                                                    },
                                                                    de: {
                                                                        name: values.translations?.de?.name
                                                                    },
                                                                    fr: {
                                                                        name: values.translations?.fr?.name
                                                                    }
                                                                },
                                                            }
                                                        }, options)
                                                    } else {
                                                        values.details.forEach((detail, index) => {
                                                            createDriveMutation.mutate({
                                                                accessToken,
                                                                data: {
                                                                    driveGroup: gateGroup.id,
                                                                    name: values.name,
                                                                    minHeight: +values.details[index].minHeight,
                                                                    maxHeight: +values.details[index].maxHeight,
                                                                    minTotalHeight: +values.details[index].minTotalHeight,
                                                                    maxTotalHeight: +values.details[index].maxTotalHeight,
                                                                    depth: +values.details[index].depth,
                                                                    leftArea: +values.details[index].leftArea,
                                                                    rightArea: +values.details[index].rightArea,
                                                                    ktm: values?.ktm,
                                                                    maximalSurface: values?.drives[0]?.maximalSurface > 0 ? values?.drives[0]?.maximalSurface : 1,
                                                                    ktmDescription: values?.drives[0]?.ktmDescription,
                                                                    leadings: values?.details[index]?.leadings?.map(i => i.split('/').reverse()[0]),
                                                                    image: data?.id,
                                                                    render: render?.id,
                                                                    translations: {
                                                                        en: {
                                                                            name: values.translations?.en?.name
                                                                        },
                                                                        ru: {
                                                                            name: values.translations?.ru?.name
                                                                        },
                                                                        de: {
                                                                            name: values.translations?.de?.name
                                                                        },
                                                                        fr: {
                                                                            name: values.translations?.fr?.name
                                                                        }
                                                                    },
                                                                }
                                                            }, options)
                                                        })
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                        setChosenListItem(null)
                        setInitValues(initialValues)
                        resetForm();
                    }}
                >
                    {({values, resetForm, setFieldValue, errors}) => {

                        return (
                            <>
                                {(createDriveGroupMutation.isLoading || createDriveMutation.isLoading || editDriveGroupMutation.isLoading || editDriveMutation.isLoading) && (
                                    <div className="w-100 text-center">
                        <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border text-center spinner-border-sm align-middle ms-2'></span>
                        </span>
                                    </div>

                                )}
                                {(createDriveGroupMutation.isSuccess || createDriveMutation.isSuccess || editDriveGroupMutation.isSuccess || editDriveMutation.isSuccess) &&
                                    <p className="text-center fw-bold"
                                       style={{color: "green", fontSize: 16}}>Zapisano!</p>}
                                {(!createDriveGroupMutation.isLoading && !editDriveGroupMutation.isLoading) && (!createDriveGroupMutation.isSuccess && !editDriveGroupMutation.isSuccess) &&
                                    <Form>
                                        <div className='row mb-6 d-flex flex-column'>
                                            <div className='d-flex flex-column col-12 col-sm-6'>
                                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Nazwa
                                                    napędu</label>
                                                <div className='col-lg-12 fv-row'>
                                                    <Field
                                                        name="name"
                                                        type='text'
                                                        className='form-control form-control-lg  form-control-solid'
                                                        placeholder='Wprowadź nazwę napędu'
                                                    />
                                                </div>
                                            </div>
                                            {errors?.name &&
                                                <p className={'fv-help-block'}>Pole "Nazwa" jest wymagane</p>}
                                        </div>
                                        <p className='col-lg-12 col-form-label fw-bold fs-4 '>Tłumaczenia</p>
                                        <div className='d-flex flex-column col-12'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>EN</label>

                                            <div className='col-lg-12 fv-row'>
                                                <Field
                                                    name="translations.en.name"
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Wprowadź tłumaczenie w języku angielskim'
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column col-12 mb-5'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>RU</label>

                                            <div className='col-lg-12 fv-row'>
                                                <Field
                                                    name="translations.ru.name"
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Wprowadź tłumaczenie w języku rosyjskim'
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column col-12'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>DE</label>

                                            <div className='col-lg-12 fv-row'>
                                                <Field
                                                    name="translations.de.name"
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Wprowadź tłumaczenie w języku niemieckim'
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column col-12 mb-6'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>FR</label>

                                            <div className='col-lg-12 fv-row'>
                                                <Field
                                                    name="translations.fr.name"
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Wprowadź tłumaczenie w języku francuskim'
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6 d-flex flex-sm-row flex-column'>

                                            <div className='d-flex flex-column col-12 col-sm-6'>
                                                {chosenListItem && showImg && <img
                                                    style={{
                                                        height: 'auto',
                                                        width: '100%',
                                                        maxWidth: 100
                                                    }}
                                                    src={`${process.env.REACT_APP_API_URL}` + chosenListItem?.image?.contentUrl}
                                                    alt="item foto"
                                                />}
                                                <div className='d-flex flex-column'>
                                                    <label
                                                        htmlFor="file"
                                                        className='col-lg-6 col-form-label fw-bold fs-6'
                                                        title='Change avatar'>
                                                        Zdjęcie
                                                    </label>
                                                    <input id="file" name="file" type="file" onChange={(event) => {
                                                        setShowImg(false)
                                                        setFieldValue("file", event.currentTarget.files[0]);
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column col-12 col-sm-6'>
                                                {chosenListItem && showRender && <img
                                                    style={{
                                                        height: 'auto',
                                                        width: '100%',
                                                        maxWidth: 100
                                                    }}
                                                    src={`${process.env.REACT_APP_API_URL}` + chosenListItem?.render?.contentUrl}
                                                    alt="item foto"
                                                />}
                                                <div className='d-flex flex-column'>
                                                    <label
                                                        htmlFor="file2"
                                                        className='col-lg-6 col-form-label fw-bold fs-6'
                                                        title='Change avatar'>
                                                        Render
                                                    </label>
                                                    <input id="file2" name="file2" type="file" onChange={(event) => {
                                                        setShowRender(false)
                                                        setFieldValue("render", event.currentTarget.files[0]);
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-6 d-flex'>
                                            <div className='d-flex flex-column col-12 col-sm-6'>
                                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Kod
                                                    KTM</label>
                                                <div className='col-lg-12 fv-row'>
                                                    <Field
                                                        name="ktm"
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Wprowadź kod KTM.'
                                                    />
                                                </div>
                                                {errors?.ktm &&
                                                    <p className={'fv-help-block'}>Pole "KTM" jest wymagane</p>}
                                            </div>
                                            <div
                                                className='d-flex flex-column col-12 col-sm-6'>
                                                <label className='col-lg-6 col-form-label fw-bold fs-6'>Opis
                                                    skrócony</label>
                                                <div className='col-lg-12 fv-row'>
                                                    <Field
                                                        name="drives[0].ktmDescription"
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Wprowadź opis skrócony kodu KTM...'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-6 d-flex'>
                                            <div className='d-flex flex-column col-12 col-sm-6'>
                                                <label className='col-lg-12 col-form-label fw-bold fs-6'>Max.
                                                    powierzchnia bramy m2</label>
                                                <div className='col-lg-12 fv-row'>
                                                    <Field
                                                        name="drives[0].maximalSurface"
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Wprowadź max. powierzchnię bramy'
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column col-12 col-sm-6'>
                                                <label className='col-lg-6 col-form-label fw-bold fs-6'>Cena</label>
                                                <div className='col-lg-12 fv-row'>
                                                    <Field
                                                        name="price"
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Wprowadź cenę napędu'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <FieldArray name="details">
                                            {({insert, remove, push}) => (
                                                <div>
                                                    {values?.details?.length > 0 &&
                                                        values.details.map((detail, index) => {
                                                            if (detail.minHeight === 99999999) {
                                                                <></>
                                                            } else {
                                                                return (
                                                                    <div className="row" key={index}>
                                                                        <div className="form-group row mt-4 mb-4">
                                                                            <div className="col-md-6">
                                                                                <label
                                                                                    htmlFor={`details.${index}.minHeight`}
                                                                                    className="form-label">Minimalna
                                                                                    wysokość nadproża:</label>
                                                                                <Field
                                                                                    name={`details.${index}.minHeight`}
                                                                                    type="number"
                                                                                    required
                                                                                    min={1}
                                                                                    max={99999999999}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder="Wpisz wartość"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`details.${index}.minHeight`}
                                                                                    component="div"
                                                                                    className="field-error"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label
                                                                                    htmlFor={`details.${index}.maxHeight`}
                                                                                    className="form-label">
                                                                                    Maksymalna wysokość nadproża
                                                                                </label>
                                                                                <Field
                                                                                    name={`details.${index}.maxHeight`}
                                                                                    type="number"
                                                                                    required
                                                                                    min={1}
                                                                                    max={99999999999}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder="Wpisz wartość"/>
                                                                                <ErrorMessage
                                                                                    name={`details.${index}.maxHeight`}
                                                                                    component="div"
                                                                                    className="field-error"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 mt-4">
                                                                                <label
                                                                                    htmlFor={`details.${index}.rightArea`}
                                                                                    className="form-label">
                                                                                    Minimalna przestrzeń boczna prawa:
                                                                                </label>
                                                                                <Field
                                                                                    name={`details.${index}.rightArea`}
                                                                                    type="number"
                                                                                    min={1}
                                                                                    required
                                                                                    max={99999999999}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder="Wpisz wartość"/>
                                                                                <ErrorMessage
                                                                                    name={`details.${index}.rightArea`}
                                                                                    component="div"
                                                                                    className="field-error"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 mt-4">
                                                                                <label
                                                                                    htmlFor={`details.${index}.leftArea`}
                                                                                    className="form-label">
                                                                                    Minimalna przestrzeń boczna lewa:
                                                                                </label>
                                                                                <Field
                                                                                    name={`details.${index}.leftArea`}
                                                                                    type="number"
                                                                                    min={1}
                                                                                    max={99999999999}
                                                                                    required
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder="Wpisz wartość"/>
                                                                                <ErrorMessage
                                                                                    name={`details.${index}.leftArea`}
                                                                                    component="div"
                                                                                    className="field-error"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 mt-4">
                                                                                <label
                                                                                    htmlFor={`details.${index}.minTotalHeight`}
                                                                                    className="form-label">
                                                                                    Minimalna wysokość otworu:
                                                                                </label>
                                                                                <Field
                                                                                    name={`details.${index}.minTotalHeight`}
                                                                                    type="number"
                                                                                    min={1}
                                                                                    max={99999999999}
                                                                                    required
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder="Wpisz wartość"/>
                                                                                <ErrorMessage
                                                                                    name={`details.${index}.minTotalHeight`}
                                                                                    component="div"
                                                                                    className="field-error"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 mt-4">
                                                                                <label
                                                                                    htmlFor={`details.${index}.maxTotalHeight`}
                                                                                    className="form-label">
                                                                                    Maksymalna wysokość otworu:
                                                                                </label>
                                                                                <Field
                                                                                    name={`details.${index}.maxTotalHeight`}
                                                                                    type="number"
                                                                                    min={1}
                                                                                    max={99999999999}
                                                                                    required
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder="Wpisz wartość"/>
                                                                                <ErrorMessage
                                                                                    name={`details.${index}.depth`}
                                                                                    component="div"
                                                                                    className="field-error"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 mt-4">
                                                                                <label
                                                                                    htmlFor={`details.${index}.depth`}
                                                                                    className="form-label">
                                                                                    Minimalna głębokość otworu:
                                                                                </label>
                                                                                <Field
                                                                                    name={`details.${index}.depth`}
                                                                                    type="number"
                                                                                    min={1}
                                                                                    max={99999999999}
                                                                                    required
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder="Wpisz wartość"/>
                                                                                <ErrorMessage
                                                                                    name={`details.${index}.depth`}
                                                                                    component="div"
                                                                                    className="field-error"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div
                                                                                    className="form-check form-check-custom form-check-solid mt-2 mt-md-11 gap-4 flex-wrap"
                                                                                    role="group">
                                                                                    {leading && leading[0]?.componentItems?.map((item, i) => {

                                                                                        return <div key={item.id}
                                                                                                    className="col-6">
                                                                                            <Field
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                value={item.id}
                                                                                                name={`details[${index}].leadings`}/>
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="form_checkbox">
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    })}
                                                                                </div>
                                                                                <div
                                                                                    className="col-12 d-flex justify-content-end">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-sm btn-light-danger mt-3 mt-md-8"
                                                                                        onClick={() => {
                                                                                            deleteSingleDrive.mutate({
                                                                                                accessToken,
                                                                                                driveId: detail.id
                                                                                            })
                                                                                            remove(index)
                                                                                        }}
                                                                                    >
                                                                                        Usuń
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary  mt-3 mt-md-8"
                                                        onClick={() => push({
                                                            minHeight: 1,
                                                            maxHeight: 1,
                                                            leftArea: 1,
                                                            rightArea: 1,
                                                            minTotalHeight: 1,
                                                            maxTotalHeight: 1,
                                                            depth: 1,
                                                        })}
                                                    >
                                                        Dodaj
                                                    </button>
                                                </div>
                                            )}
                                        </FieldArray>
                                        <div className="col-md-12 mt-5 d-flex justify-content-end">
                                            {createDriveMutation.isError &&
                                                <p style={{color: "red", fontWeight: 500, margin: "auto"}}
                                                   className="text-center">Minimalne wartości napędu muszą być większe
                                                    od 0!</p>}
                                            <button
                                                style={{backgroundColor: errors?.name || errors?.ktm ? 'grey' : '#009EF7'}}
                                                disabled={errors?.name || errors?.ktm}
                                                className='btn btn-primary'
                                                type="submit">Zapisz
                                            </button>
                                        </div>
                                    </Form>}
                            </>

                        )
                    }}
                </Formik>
            </div>
        </>
    )
}

export {DriveCreateForm}
