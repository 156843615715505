/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import SubMenu from "../../component/subMenu/SubMenu";
import {useQuery} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {fetchAllVat} from "./VatFunctions";
import {IVatDetails} from "./VatAddModel";
import Modal from "@mui/material/Modal";
import {style, theme} from "../adminList/Admin";
import Box from "@mui/material/Box";
import {VatCreateModalForm} from "./VatCreateModalForm";

type Props = {
    className: string
}

const VatTable: React.FC<Props> = ({className}) => {
    const [chosenListItem, setChosenListItem] = useState<IVatDetails | null>(null)
    const [open, setOpen] = React.useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    const {data: vatRates, isLoading, isFetching} = useQuery("vatRates", () => fetchAllVat(accessToken))

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5 d-flex flex-nowrap gap-5 justify-content-end'>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Kliknij, aby dodać użytkownika'
                >
                    <button
                        className='btn btn-sm btn-light-primary'
                        onClick={() => setOpen(!open)}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                        <span className="d-none d-sm-inline">Dodaj</span>
                    </button>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                {vatRates?.length === 0 && <p className="mt-5">Brak elementów do wyświetlenia</p>}
                {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                {vatRates?.length > 0 && !isLoading && <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                        <tr className='fw-bolder text-muted'>
                            <th>Stawka Vat</th>
                            <th>Akcje</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {vatRates?.length > 0 && vatRates?.map(vat => (
                            <tr>
                                <td>{vat.rate}</td>
                                <td>
                                    <SubMenu
                                        item={vat}
                                        setChosenListItem={setChosenListItem}
                                        chosenListItem={chosenListItem}
                                        modalOpen={open}
                                        setOpen={setOpen}
                                        url={"vat_rates"}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>}
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={style}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpen(!open)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} stawkę Vat</h1>
                                    </div>
                                    <VatCreateModalForm setChosenListItem={setChosenListItem}
                                                     chosenListItem={chosenListItem}
                                                     modalOpen={open} setOpen={setOpen}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
        </div>
    )
}

export {VatTable}
