import React, {useEffect, useState} from 'react'
import {contentInitValues as initialValues, IContentDetails} from './ConentEditModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useMutation, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {createNewContent, updateContentById} from "./ContentFunctions";
import {CircularProgress} from "@mui/material";

const contentSchema = Yup.object().shape({
    slug: Yup.string().required('Pole "Nazwa" jest wymagane').nullable(),
    content: Yup.string().required('Pole "Treść" jest wymagane').nullable(),
})

const ContentEditForm: React.FC<{ chosenListItem: IContentDetails | null }> = (props) => {
    const {setChosenListItem, chosenListItem, modalOpen, setOpen} = props;
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const client = useQueryClient();
    const [initValues, setInitValues] = useState<IContentDetails>(initialValues);
    const [showImg, setShowImg] = useState<boolean>(true);

    useEffect(() => {
        if (chosenListItem) {
            setInitValues(chosenListItem)
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])

    const contentCreateMutation = useMutation(createNewContent, {
        onSuccess: () => {
            client.invalidateQueries('contents')
            setInitValues(initialValues)
            setChosenListItem(null)
        }
    })

    const contentEditMutation = useMutation(updateContentById, {
        onSuccess: () => {
            client.invalidateQueries('contents')
            setInitValues(initialValues)
            setChosenListItem(null)
        }
    })


    const [loading, setLoading] = useState(false)
    const formik = useFormik<IContentDetails>({
        initialValues: initValues,
        validationSchema: contentSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const options = {
                onSuccess: () => {
                    setTimeout(() => {
                        setOpen(prev => !prev)
                    }, 2000)
                }
            }
            if (chosenListItem) {
                contentEditMutation.mutate({
                    accessToken,
                    data: {
                        slug: values.slug,
                        content: values.content,
                        translations: {
                            en: {
                                name: values.translations?.en?.name
                            },
                            ru: {
                                name: values.translations?.ru?.name
                            },
                            de: {
                                name: values.translations?.de?.name
                            },
                            fr: {
                                name: values.translations?.fr?.name
                            }
                        },
                    },
                    contentId: chosenListItem.id
                }, options)
            } else {
                contentCreateMutation.mutate({
                    accessToken,
                    data: {
                        slug: values.slug,
                        content: values.content,
                        translations: {
                            en: {
                                name: values.translations?.en?.name
                            },
                            ru: {
                                name: values.translations?.ru?.name
                            },
                            de: {
                                name: values.translations?.de?.name
                            },
                            fr: {
                                name: values.translations?.fr?.name
                            }
                        },
                    },
                }, options)
            }
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>

                        <div className='row mb-6 d-flex'>
                            <div className='d-flex flex-column col-12 col-sm-6'>
                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Nazwa</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprwoadź nazwę pola'
                                        {...formik.getFieldProps('slug')}
                                    />
                                    {formik.touched.slug && formik.errors.slug && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.slug}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='d-flex flex-column'>
                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Treść</label>

                                <div className='col-lg-12 fv-row'>
                                    <textarea
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź treść'
                                        {...formik.getFieldProps('content')}
                                    />
                                    {formik.touched.content && formik.errors.content && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.content}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <p className='col-lg-12 col-form-label fw-bold fs-4 mt-5'>Tłumaczenia</p>
                            <div className='d-flex flex-column col-12'>
                                <label className='col-lg-12 col-form-label fw-bold fs-6'>EN</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź tłumaczenie w języku angielskim'
                                        {...formik.getFieldProps('translations.en.name')}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column col-12'>
                                <label className='col-lg-12 col-form-label fw-bold fs-6'>RU</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź tłumaczenie w języku rosyjskim'
                                        {...formik.getFieldProps('translations.ru.name')}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column col-12'>
                                <label className='col-lg-12 col-form-label fw-bold fs-6'>DE</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź tłumaczenie w języku niemieckim'
                                        {...formik.getFieldProps('translations.de.name')}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column col-12'>
                                <label className='col-lg-12 col-form-label fw-bold fs-6'>FR</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź tłumaczenie w języku francuskim'
                                        {...formik.getFieldProps('translations.fr.name')}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column col-12 col-sm-6'>
                                <div className="symbol symbol-45px">
                                    {showImg && chosenListItem?.image?.contentUrl ? <img
                                        style={{
                                            height: 'auto',
                                            width: '100%',
                                            maxWidth: 100
                                        }}
                                        src={`${process.env.REACT_APP_API_URL}` + chosenListItem?.image?.contentUrl}
                                        alt="item foto"
                                    /> : null}
                                </div>
                                <label className='col-lg-6 col-form-label fw-bold fs-6'
                                       title='Change avatar'> Zdjęcie</label>
                                <input id="file" name="file" type="file" onChange={(event) => {
                                    setShowImg(false)
                                    formik.setFieldValue("file", event.currentTarget.files[0]);
                                }}/>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        {contentEditMutation.isIdle &&
                            <button type='submit' className='btn btn-primary'>
                                {chosenListItem ? 'Zapisz' : 'Dodaj'}
                            </button>}
                    </div>
                    {contentEditMutation.isLoading &&
                        <div className="d-flex justify-content-center"><CircularProgress/></div>}
                </form>
            </div>
            {contentEditMutation.isSuccess &&
                <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
        </div>
    )
}

export {ContentEditForm}
