import React, {useEffect, useState} from 'react'
import {componentItemsInitValues as initialValues, IComponentItems,} from './AccessoriesAddModel';
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik'
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createComponentItemImg} from "../componentCreate/ComponentsCreateFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useParams} from "react-router-dom";
import CustomSelect from "../../component/customSelect/CustomSelect";
import {getAllGateTypes} from "../components/gateCategory/GateCategoryFunctions";
import {getAllDrivesGroups} from "../components/drive/DriveFunctions";
import {createAccessory, updateAccessoryById} from "./AccessoriesFunctions";
import {fetchAllCategories} from "../accessoriesCategories/AccessoriesCategoriesFunctions";
import {increasePosition} from "../../component/customChangePosition/customChangePosition";
import {priceTypes} from "../components/ComponentCreateForm";

const AccessoriesCreateFormValidationSchema = [Yup.object().shape({
    name: Yup.string().required('Pole "Nazwa" jest wymagane').nullable(),
    category: Yup.string().required('Pole "Kategoria" jest wymagane').nullable(),
    price: Yup.number().required('Pole "Cena" jest wymagane').nullable(),
    ktm: Yup.string().required('Pole "KTM" jest wymagane').nullable(),
    ktmDescription: Yup.string().required('Pole "Opis skrócony" jest wymagane').nullable(),
}), Yup.object().shape({
    name: Yup.string().required('Pole "Nazwa" jest wymagane').nullable(),
    category: Yup.string().required('Pole "Kategoria" jest wymagane').nullable(),
    price: Yup.number().required('Pole "Cena" jest wymagane').nullable(),
    priceType: Yup.string().required('Pole "Typ ceny" jest wymagane').nullable(),
    ktm: Yup.string().required('Pole "KTM" jest wymagane').nullable(),
    ktmDescription: Yup.string().required('Pole "Opis skrócony" jest wymagane').nullable()
}), Yup.object().shape({
    name: Yup.string().required('Pole "Nazwa" jest wymagane').nullable(),
    category: Yup.string().required('Pole "Kategoria" jest wymagane').nullable(),
    price: Yup.number().required('Pole "Cena" jest wymagane').nullable(),
    perMeter: Yup.string().required('Pole "Ilość elementów na mb" jest wymagane').nullable(),
    priceType: Yup.string().required('Pole "Typ ceny" jest wymagane').nullable(),
    ktm: Yup.string().required('Pole "KTM" jest wymagane').nullable(),
    ktmDescription: Yup.string().required('Pole "Opis skrócony" jest wymagane').nullable()
})]

const panelPositionOptions = [
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
    {value: 4, label: 4},
    {value: 5, label: 5},
    {value: 6, label: 6},
    {value: 7, label: 7},
    {value: 8, label: 8},
    {value: 9, label: 9},
    {value: 10, label: 10},
    {value: 11, label: 11},
    {value: 12, label: 12},
    {value: 13, label: 13},
    {value: 14, label: 14},
    {value: 15, label: 15},
    {value: 16, label: 16},
    {value: 17, label: 17},
    {value: 18, label: 18},
    {value: 19, label: 19},
    {value: 20, label: 20},
    {value: 21, label: 21},
    {value: 22, label: 22},
]

const AccessoriesCreateForm: React.FC<{
    component: { title: string, component_items: {}[] },
    chosenListItem: {} | null,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = (props) => {
    const {chosenListItem, setOpen, component, setChosenListItem, accessories, allAccessories} = props;
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const params = useParams();
    const client = useQueryClient();
    const [formikHasErrors, setFormikHasErrors] = useState(false)
    const [showImg, setShowImg] = useState<boolean>(true);
    const [showRender, setShowRender] = useState<boolean>(true);
    const [perMeterValidation, setPerMeterValidation] = useState<boolean>(false);
    const [initValues, setInitValues] = useState<IComponentItems>(initialValues);
    const {data: gateTypes} = useQuery("gate_types", () => getAllGateTypes(accessToken));
    const {data: drives, isLoading} = useQuery('drives', () => getAllDrivesGroups(accessToken));
    const {data: accessoriesCategories} = useQuery("accessoriesCategories", () => fetchAllCategories(accessToken))
    const gateTypesOptions = gateTypes?.map((gate: { id: string, name: string }) => {
        return {value: gate.id, label: gate.name}
    })
    const drivesOptions = drives?.map((gate: { id: string, name: string }) => {
        return {value: gate.id, label: gate.name}
    })

    const accessoriesCatOptions = accessoriesCategories?.map((gate: { id: string, name: string }) => {
        return {value: gate.id, label: gate.name}
    })
    const accessoryItemImgMutation = useMutation(createComponentItemImg)

    const accessoryItemCreateMutation = useMutation(createAccessory, {
        onSuccess: (id) => {
            client.invalidateQueries(params.type === "gate" ? "gate_accessories" : "accessories")
        }
    })

    const accessoryItemUpdateMutation = useMutation(updateAccessoryById, {
        onSuccess: (id) => {
            client.invalidateQueries(params.type === "gate" ? "gate_accessories" : "accessories")
        }
    })

    const changePositionMutation = useMutation(increasePosition, {
        onSuccess: () => {
            client.invalidateQueries(params.type === "gate" ? "gate_accessories" : "accessories")
        }
    })
    useEffect(() => {
        if (chosenListItem) {
            setInitValues({
                ...chosenListItem,
                category: chosenListItem.category.id,
                price: chosenListItem?.price / 100,
                priceType: chosenListItem?.priceType >= 0 ? chosenListItem?.priceType.toString() : undefined
            })

        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])
    const formik = useFormik<IComponentItems>({
        initialValues: initValues,
        enableReinitialize: true,
        validationSchema: AccessoriesCreateFormValidationSchema[params.type === "gate" && !perMeterValidation ? 1 : params.type === "gate" && perMeterValidation ? 2 : 0],
        onSubmit: (values, errors) => {
            const componentItems = values?.componentItemsValues?.map(item => {
                return Object.entries(item)
                    ?.map((entry) => entry[1].map((e) => ({...e, column: entry[0]})))
                    ?.reduce((prev, accumul) => [...prev, ...accumul], []);
            })

            const componentItemsValues = componentItems?.reduce((prev, accumul) => [...prev, ...accumul], []);
            const options = {
                onSuccess: () => {
                    formik.resetForm()
                    setChosenListItem(null)
                    setInitValues(initialValues)
                    setPerMeterValidation(false)
                    setTimeout(() => {
                        setOpen(prev => !prev)
                    }, 2000)
                }
            }
            if (chosenListItem && chosenListItem.id) {
                accessoryItemImgMutation.mutate({
                    accessToken,
                    data: values.file,
                    render: null
                }, {
                    onSuccess: (data) => {
                        accessoryItemImgMutation.mutate({
                            accessToken,
                            data: null,
                            render: values.render
                        }, {
                            onSuccess: (render) => {
                                accessoryItemUpdateMutation.mutate({
                                    accessToken,
                                    data: {
                                        name: values?.name,
                                        price: Math.round(values?.price * 100),
                                        ktm: values?.ktm,
                                        ktmDescription: values.ktmDescription,
                                        maxWidth: values?.maxWidth,
                                        minWidth: values?.minWidth,
                                        maxQuantity: values?.maxQuantity,
                                        gateType: values?.gateType?.map(gate => gate.id),
                                        category: values?.category,
                                        driveGroups: values?.driveGroups?.map(drive => drive.id),
                                        isCustomPosition: values?.isCustomPosition,
                                        isSoloRender: values?.isSoloRender,
                                        description: values?.description,
                                        image: data ? data.id : chosenListItem.image ? chosenListItem.image?.id : null,
                                        render: render ? render.id : chosenListItem.render ? chosenListItem.render?.id : null,
                                        renderQuantity: values?.renderQuantity,
                                        multipleByGateWidth: values?.multipleByGateWidth,
                                        panelPosition: values?.panelPosition || [],
                                        perMeter: values?.perMeter,
                                        youtubeUrl: values?.youtubeUrl,
                                        priceType: +values.priceType,
                                        horizontalElementPosition: values.horizontalElementPosition,
                                        gateAccessoryOrder: values.gateAccessoryOrder,
                                        translations: {
                                            en: {
                                                name: values.translations?.en?.name
                                            },
                                            ru: {
                                                name: values.translations?.ru?.name
                                            },
                                            de: {
                                                name: values.translations?.de?.name
                                            },
                                            fr: {
                                                name: values.translations?.fr?.name
                                            }
                                        },
                                    },
                                    url: params.type === "gate" ? "gate_accessories" : "accessories",
                                    id: chosenListItem.id
                                }, options)
                            }
                        })
                    }
                })

            } else {
                accessoryItemImgMutation.mutate({
                    accessToken,
                    data: values.file,
                    render: null
                }, {
                    onSuccess: (data) => {
                        accessoryItemImgMutation.mutate({
                            accessToken,
                            data: null,
                            render: values.render
                        }, {
                            onSuccess: (render) => {
                                accessoryItemCreateMutation.mutate({
                                    accessToken,
                                    data: {
                                        name: values.name,
                                        price: values.price * 100,
                                        ktm: values.ktm,
                                        ktmDescription: values.ktmDescription,
                                        maxWidth: values?.maxWidth,
                                        minWidth: values?.minWidth,
                                        maxQuantity: values?.maxQuantity,
                                        gateType: values?.gateType?.map(gate => gate.id),
                                        category: values.category,
                                        driveGroups: values?.driveGroups?.map(drive => drive.id),
                                        isCustomPosition: values?.isCustomPosition,
                                        isSoloRender: values?.isSoloRender,
                                        description: values?.description,
                                        image: data?.id,
                                        render: render?.id,
                                        renderQuantity: values?.renderQuantity,
                                        multipleByGateWidth: values?.multipleByGateWidth,
                                        panelPosition: values?.panelPosition || [],
                                        perMeter: values?.perMeter,
                                        youtubeUrl: values?.youtubeUrl,
                                        horizontalElementPosition: values.horizontalElementPosition,
                                        priceType: +values.priceType,
                                        translations: {
                                            en: {
                                                name: values.translations?.en?.name
                                            },
                                            ru: {
                                                name: values.translations?.ru?.name
                                            },
                                            de: {
                                                name: values.translations?.de?.name
                                            },
                                            fr: {
                                                name: values.translations?.fr?.name
                                            }
                                        },
                                    },
                                    url: params.type === "gate" ? "gate_accessories" : "accessories"
                                }, {
                                    onSuccess: (d) => {
                                        changePositionMutation.mutate({
                                            url: params.type === "gate" ? "gate_accessories" : "accessories",
                                            id: d.id,
                                            data: {
                                                gateAccessoryOrder: params.type === "gate"?  allAccessories?.length + 1 : null,
                                                accessoryOrder: params.type !== "gate"?  allAccessories?.length + 1 : null
                                            },
                                            accessToken
                                        }, {
                                            onSuccess: () => {
                                                formik.resetForm()
                                                setChosenListItem(null)
                                                setInitValues(initialValues)
                                                client.invalidateQueries(params.type === "gate" ? `gate_accessories` : `accessories`)
                                                setTimeout(() => {
                                                    setOpen(prev => !prev)
                                                }, 2000)

                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
            formik.resetForm()
            setChosenListItem(null)
            setInitValues(initialValues)
        },
    })

    useEffect(() => {
        if (formik.values.priceType === '1') {
            setPerMeterValidation(true)
        } else {
            setPerMeterValidation(false)
        }
    }, [formik.values.priceType])

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='collapse show'>
                {(!accessoryItemUpdateMutation.isLoading && !accessoryItemCreateMutation.isLoading) && (!accessoryItemUpdateMutation.isSuccess && !accessoryItemCreateMutation.isSuccess) &&
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='card-body border-top p-9'>
                                <div className='row mb-6 d-flex flex-sm-row flex-column'>

                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <div className="symbol symbol-45px">
                                            {showImg && chosenListItem?.image?.contentUrl && <button
                                                onClick={e => {
                                                    e.preventDefault()
                                                    setChosenListItem({
                                                        ...chosenListItem,
                                                        image: null,
                                                    })
                                                }}
                                                className='position-absolute d-flex justify-content-center align-items-center cursor-pointer'
                                                style={{
                                                    width: '20px',
                                                    color: 'white',
                                                    height: '20px',
                                                    backgroundColor: 'red',
                                                    borderRadius: '50%',
                                                    left: -5,
                                                    top: -5,
                                                    outline: 'none',
                                                    border: 'none'
                                                }}>&#10006;
                                            </button>}
                                            {showImg && chosenListItem?.image?.contentUrl ? <img
                                                style={{
                                                    height: 'auto',
                                                    width: '100%',
                                                    maxWidth: 100
                                                }}
                                                src={`${process.env.REACT_APP_API_URL}` + chosenListItem?.image?.contentUrl}
                                                alt="item foto"
                                            /> : null}
                                        </div>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'
                                               title='Change avatar'> Zdjęcie</label>
                                        <input id="file" name="file" type="file" onChange={(event) => {
                                            setShowImg(false)
                                            formik.setFieldValue("file", event.currentTarget.files[0]);
                                        }}/>
                                    </div>

                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <div className="symbol symbol-45px position-relative">

                                            {showRender && chosenListItem?.render?.contentUrl && <button
                                                onClick={e => {
                                                    e.preventDefault()
                                                    setChosenListItem({
                                                        ...chosenListItem,
                                                        render: null,
                                                    })
                                                }}
                                                className='position-absolute d-flex justify-content-center align-items-center cursor-pointer'
                                                style={{
                                                    width: '20px',
                                                    color: 'white',
                                                    height: '20px',
                                                    backgroundColor: 'red',
                                                    borderRadius: '50%',
                                                    left: -5,
                                                    top: -5,
                                                    outline: 'none',
                                                    border: 'none'
                                                }}>&#10006;
                                            </button>}
                                            {showRender && chosenListItem?.render?.contentUrl ? <img
                                                style={{
                                                    height: 'auto',
                                                    width: '100%',
                                                    maxWidth: 100
                                                }}
                                                src={`${process.env.REACT_APP_API_URL}` + chosenListItem?.render?.contentUrl}
                                                alt="item foto"
                                            /> : null}
                                        </div>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'
                                               title='Change avatar'> Render</label>
                                        <input id="file" name="file" type="file" onChange={(event) => {
                                            setShowRender(false)
                                            formik.setFieldValue("render", event.currentTarget.files[0]);
                                        }}/>
                                    </div>
                                </div>
                                <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label required fw-bold fs-6'>Nazwa</label>
                                        <div>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Nazwa'
                                                {...formik.getFieldProps('name')}
                                            />
                                        </div>
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.name}</div>
                                            </div>
                                        )}
                                    </div>
                                    {params.type !== "gate" && <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className='col-lg-6 col-form-label required fw-bold fs-6'>Cena</label>
                                        <div>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Cena komponentu...'
                                                {...formik.getFieldProps('price')}
                                            />
                                        </div>
                                        {formik.touched.price && formik.errors.price && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.price}</div>
                                            </div>
                                        )}
                                    </div>}
                                </div>
                                <p className='col-lg-12 col-form-label fw-bold fs-4 mt-5'>Tłumaczenia</p>
                                <div className='d-flex flex-column col-12'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>EN</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź tłumaczenie w języku angielskim'
                                            {...formik.getFieldProps('translations.en.name')}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 mb-5'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>RU</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź tłumaczenie w języku rosyjskim'
                                            {...formik.getFieldProps('translations.ru.name')}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 mb-5'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>DE</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź tłumaczenie w języku niemieckim'
                                            {...formik.getFieldProps('translations.de.name')}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 mb-5'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>FR</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź tłumaczenie w języku francuskim'
                                            {...formik.getFieldProps('translations.fr.name')}
                                        />
                                    </div>
                                </div>
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 required text-hover-primary fs-5">Typ
                                            ceny</label>
                                        <Field
                                            className="custom-select"
                                            name="priceType"
                                            options={priceTypes}
                                            component={CustomSelect}
                                            placeholder="Wybierz typ ceny..."
                                            isMulti={false}
                                        />
                                    </div>
                                    {formik.touched.priceType && formik.errors.priceType && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.priceType}</div>
                                        </div>
                                    )}
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className='col-lg-6 col-form-label required fw-bold fs-6'>Cena</label>
                                        <div>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Cena komponentu...'
                                                {...formik.getFieldProps('price')}
                                            />
                                        </div>
                                        {formik.touched.price && formik.errors.price && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.price}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>}
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className={`col-lg-6 col-form-label fw-bold fs-6 ${formik?.values.priceType === '1' ? "required" : ""}`}>Ilość
                                            elementów na mb</label>
                                        <div>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Ilość elementów na mb...'
                                                {...formik.getFieldProps('perMeter')}
                                            />
                                        </div>
                                        {formik?.values.priceType === '1' && formik.errors.perMeter && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.perMeter}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'></div>
                                </div>}
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                    <span className="d-flex flex-column">
								        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">Rodzaj bram</label>
								        <Field
                                            className="custom-select"
                                            name="gateType"
                                            options={gateTypesOptions}
                                            component={CustomSelect}
                                            placeholder="Wybierz rodzaje bram..."
                                            isMulti={true}
                                        />
                                    </span>
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                    <span className="d-flex flex-column">
								        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">Napęd</label>
								        <Field
                                            className="custom-select"
                                            name="driveGroups"
                                            options={drivesOptions}
                                            component={CustomSelect}
                                            placeholder="Wybierz napęd..."
                                            isMulti={true}
                                        />
                                    </span>
                                    </div>
                                </div>}
                                <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                    <span className="d-flex flex-column">
								        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">Kategoria</label>
								        <Field
                                            className="custom-select"
                                            name="category"
                                            options={accessoriesCatOptions}
                                            component={CustomSelect}
                                            placeholder="Wybierz kategorię..."
                                            isMulti={false}
                                        />
                                        {formik.errors.category && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.category}</div>
                                            </div>
                                        )}
                                    </span>
                                    </div>
                                </div>
                                <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label required fw-bold fs-6'>KTM</label>
                                        <div>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Wpisz KTM...'
                                                {...formik.getFieldProps('ktm')}
                                            />
                                        </div>
                                        {formik.errors.ktm && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ktm}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label required fw-bold fs-6'>Opis
                                            skrócony</label>
                                        <div>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Opis skrócony...'
                                                {...formik.getFieldProps('ktmDescription')}
                                            />
                                        </div>
                                        {formik.errors.ktmDescription && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ktmDescription}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className='col-lg-6 col-form-label fw-bold fs-6'>Możliwość
                                            ustalania pozycji</label>
                                        <div>
                                            <input type="checkbox"
                                                   checked={formik.values.isCustomPosition}
                                                   {...formik.getFieldProps('isCustomPosition')}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                    <span className="d-flex flex-column">
								        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">Pozycja na panelu</label>
								        <Field
                                            className="custom-select"
                                            name="panelPosition"
                                            options={panelPositionOptions}
                                            component={CustomSelect}
                                            placeholder="Pozycja na panelu..."
                                            isMulti={true}
                                        />
                                    </span>
                                    </div>
                                </div>}
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                    <span className="d-flex flex-column">
								        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">Umiejscowienie elementu</label>
								        <Field
                                            className="custom-select"
                                            name="horizontalElementPosition"
                                            options={[
                                                {value: 0, label: 'Lewa'},
                                                {value: 1, label: 'Środek'},
                                                {value: 2, label: 'Prawa'}
                                            ]}
                                            component={CustomSelect}
                                            placeholder="Wybierz umiejscowienie..."
                                            isMulti={false}
                                        />
                                    </span>
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className='col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5'>Maksymalna
                                            ilość</label>
                                        <div>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Maksymalna ilość...'
                                                {...formik.getFieldProps('maxQuantity')}
                                            />
                                        </div>
                                    </div>
                                </div>}
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className='col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5'>Ilość
                                            renderów</label>
                                        <div>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Maksymalna ilość...'
                                                {...formik.getFieldProps('renderQuantity')}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className='col-lg-6 col-form-label fw-bold fs-6'>Render zależny od
                                            szerokości</label>
                                        <div>
                                            <input type="checkbox"
                                                   checked={formik.values.multipleByGateWidth}
                                                   {...formik.getFieldProps('multipleByGateWidth')}
                                            />
                                        </div>
                                    </div>
                                </div>}
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className='col-lg-6 col-form-label fw-bold fs-6'>Pojedyńczy render</label>
                                        <div>
                                            <input type="checkbox"
                                                   checked={formik.values.isSoloRender}
                                                   {...formik.getFieldProps('isSoloRender')}
                                            />
                                        </div>
                                    </div>
                                </div>}
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>Minimalna szerokość
                                            bramy</label>
                                        <div>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Minimalna szerokość bramy...'
                                                {...formik.getFieldProps('minWidth')}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>Maksymalna szerokość
                                            bramy</label>
                                        <div>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Maksymalna szerokość bramy...'
                                                {...formik.getFieldProps('maxWidth')}
                                            />
                                        </div>
                                    </div>
                                </div>}
                                {params.type === "gate" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>Pozycja</label>
                                        <div>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Minimalna szerokość bramy...'
                                                {...formik.getFieldProps('gateAccessoryOrder')}
                                            />
                                        </div>
                                    </div>
                                </div>}

                                <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-12'>
                                    <span className="d-flex flex-column">
								        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">Opis</label>
								        <textarea
                                            style={{resize: "none"}}
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź treść'
                                            maxLength={500}
                                            {...formik.getFieldProps('description')}
                                        />
                                        {formik.touched.description && formik.errors.description && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.description}</div>
                                            </div>
                                        )}
                                    </span>
                                    </div>
                                </div>
                                <div className='row mb-12 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-12'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>Link do filmu</label>
                                        <div>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Link do filmu'
                                                {...formik.getFieldProps('youtubeUrl')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {params.type === "ktm" && <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label required fw-bold fs-6'>Kod KTM</label>
                                        <div>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Kod KTM...'
                                                {...formik.getFieldProps('ktm')}
                                            />
                                        </div>
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.name}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label required fw-bold fs-6'>Opis
                                            skrócony</label>
                                        <div>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Opis skrócony...'
                                                {...formik.getFieldProps('ktmDescription')}
                                            />
                                        </div>
                                        {formik.touched.price && formik.errors.price && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.price}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>}

                            </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                {(accessoryItemCreateMutation.isIdle && accessoryItemUpdateMutation.isIdle) &&
                                    <button type='submit' id={'err-btn'}
                                            className={`btn btn-primary ${Object.values(formik.errors).length && 'err-btn'}`}
                                            style={{backgroundColor: '#009EF7'}}>
                                        {chosenListItem ? 'Zapisz' : 'Dodaj'}
                                    </button>}
                            </div>
                        </form>
                    </FormikProvider>}
                {(accessoryItemUpdateMutation.isLoading || accessoryItemCreateMutation.isLoading) && (
                    <div className="w-100 text-center">
                        <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border text-center spinner-border-sm align-middle ms-2'></span>
                        </span>
                    </div>

                )}
                {(accessoryItemUpdateMutation.isSuccess || accessoryItemCreateMutation.isSuccess) &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
            </div>
        </div>
    )
}

export {AccessoriesCreateForm}
