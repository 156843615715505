/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useQuery} from "react-query";
import {getAllComponents} from "../../../../app/pages/componentCreate/ComponentsCreateFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {UserModel} from "../../../../app/modules/auth/models/UserModel";
import {fetchAllAdminGroups} from "../../../../app/pages/accessGroups/AccessGroupFunctions";


export function AsideMenuMain() {
    const intl = useIntl()
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const {data: components} = useQuery("components", () => getAllComponents(accessToken), {
        refetchOnWindowFocus: false,
    });
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    const {data: adminGroups} = useQuery(["groups"], () => fetchAllAdminGroups(accessToken, ""))
    const userGroup = adminGroups?.find(e => e.id === user.userGroupId)
    return (
        <>
            {(userGroup?.isOfferAccess || userGroup?.isOrderAccess || userGroup?.isClientAccess) &&
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Panel zarządzania</span>
                    </div>
                </div>}
            {userGroup?.isOfferAccess && <AsideMenuItem
                to='/offers'
                icon='/media/icons/duotune/ecommerce/ecm001.svg'
                title={"Lista ofert"}
                fontIcon='bi-app-indicator'
            />}
            {userGroup?.isOrderAccess && <AsideMenuItem
                to='/orders'
                icon='/media/icons/duotune/art/art002.svg'
                title={"Lista zamowień"}
                fontIcon='bi-app-indicator'
            />}
            {userGroup?.isClientAccess && <AsideMenuItem
                to='/client-list'
                icon='/media/icons/duotune/art/art002.svg'
                title={"Lista kontrahentów"}
                fontIcon='bi-app-indicator'
            />}
            {userGroup?.isComponentAccess && <>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Komponenty</span>
                    </div>
                </div>
                <AsideMenuItem
                    to='/gate-category/list'
                    icon='/media/icons/duotune/art/art002.svg'
                    title={"Rodzaje bram"}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/drive'
                    icon='/media/icons/duotune/art/art002.svg'
                    title={"Rodzaje napędów"}
                    fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                    to='/component-create/new'
                    icon='/media/icons/duotune/art/art002.svg'
                    title={"Tworzenie komponentów"}
                    fontIcon='bi-app-indicator'
                />
                {components && components.length > 0 && <AsideMenuItemWithSub
                    to='/components'
                    title='Lista komponentów'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen022.svg'
                >
                    {components?.map(component => {
                        return <AsideMenuItem key={component.id} to={`/components/${component.id}`}
                                              title={component.name}
                                              hasBullet={true}/>
                    })}
                </AsideMenuItemWithSub>}
                <AsideMenuItem to={`/accessories/gate`}
                               title={"Akcesoria budowy bramy"}
                               fontIcon='bi-archive'
                               icon='/media/icons/duotune/general/gen022.svg'
                />
                <AsideMenuItem to={`/accessories/extra`}
                               title={"Akcesoria dodatkowe"}
                               fontIcon='bi-archive'
                               icon='/media/icons/duotune/general/gen022.svg'/>
                <AsideMenuItem to={`/accessories-categories`}
                               title={"Akcesoria - kategorie"}
                               fontIcon='bi-archive'
                               icon='/media/icons/duotune/general/gen022.svg'/>
                <AsideMenuItem
                    to='/structure-ktm'
                    icon='/media/icons/duotune/art/art002.svg'
                    title={"Struktura KTM"}
                    fontIcon='bi-app-indicator'
                />

                <AsideMenuItem
                    to='/vat-rates'
                    icon='/media/icons/duotune/art/art002.svg'
                    title={"Stawki Vat"}
                    fontIcon='bi-app-indicator'
                />

                <AsideMenuItemWithSub
                    to='/size'
                    title='Wymiary'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen022.svg'
                >
                    <AsideMenuItem to={`/size/heights`} title={"Wysokość"} hasBullet={true}/>
                    <AsideMenuItem to={`/size/widths`} title={"Szerokość"} hasBullet={true}/>
                    <AsideMenuItem to={`/size/lintels`} title={"Nadproże"} hasBullet={true}/>
                </AsideMenuItemWithSub>

                <AsideMenuItem
                    to='/prices'
                    icon='/media/icons/duotune/art/art002.svg'
                    title={"Cennik"}
                    fontIcon='bi-app-indicator'
                />
            </>}

            {userGroup?.isGroupsAccess && <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>inne</span>
                </div>
            </div>}

            {userGroup?.isGroupsAccess && <AsideMenuItem
                to='/access-group'
                icon='/media/icons/duotune/art/art002.svg'
                title={"Grupy dostepów"}
                fontIcon='bi-app-indicator'
            />}
            {userGroup?.isAdminAccess && <AsideMenuItem
                to='/admin-list'
                icon='/media/icons/duotune/art/art002.svg'
                title={"Lista kont adminów"}
                fontIcon='bi-app-indicator'
            />}
            {userGroup?.isContentAccess && <AsideMenuItem
                to='/content-list'
                icon='/media/icons/duotune/art/art002.svg'
                title={"Lista treści stałych"}
                fontIcon='bi-app-indicator'
            />}
            {userGroup?.isStatsAccess && <AsideMenuItem
                to='/statistics'
                icon='/media/icons/duotune/art/art002.svg'
                title={"Statystyki"}
                fontIcon='bi-app-indicator'
            />}
        </>
    )
}
