export const fetchAllVat = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/vat_rates`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    const data = await response.json();
    return data;
}

export const createNewVat = async ({accessToken, data}) => {
    const response =  await fetch(`${process.env.REACT_APP_API_URL}api/vat_rates`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        throw new Error("Błąd")
    }
    if (response.ok) {
        return response;
    }

}

export const updateVatById = async ({vatId , data, accessToken}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/vat_rates/${vatId}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}
