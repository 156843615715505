import React, {useEffect, useState} from 'react'
import {IVatDetails, vatInitValues as initialValues,} from './VatAddModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useMutation, useQuery, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {UserModel} from "../../modules/auth/models/UserModel";
import {createNewVat, fetchAllVat, updateVatById} from "./VatFunctions";

const accessGroupSchema = Yup.object().shape({
    rate: Yup.string().required('Pole "stawka" jest wymagane').nullable(),
})

const VatCreateModalForm: React.FC<{ chosenListItem: IVatDetails | null }> = (props) => {
    const {setChosenListItem, chosenListItem, modalOpen, setOpen} = props;
    const client = useQueryClient();
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [data, setData] = useState<IVatDetails>(null);
    const [initValues, setInitValues] = useState<IVatDetails | null>(initialValues);
    const [showSuccess, setShowSuccess] = useState<boolean | string>(false);
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const {data: vatRates, isLoading, isFetching} = useQuery(["vatRates"], () => fetchAllVat(accessToken))
    const userGroup = vatRates?.find(e => e.id === user.userGroupId)

    useEffect(() => {
        if (chosenListItem) {
            setInitValues(chosenListItem)
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])


    const vatRateCreateMutation = useMutation(createNewVat, {
        onSuccess: () => {
            client.invalidateQueries('vatRates')
            setShowSuccess("Zapisano")
            setOpen(!modalOpen)
            setChosenListItem(null)
            setInitValues(initialValues)
        }
    })

    const vatRateEditMutation = useMutation(updateVatById, {
        onSuccess: () => {
            client.invalidateQueries('vatRates')
            setShowSuccess("Zapisano")
            setOpen(!modalOpen)
            setChosenListItem(null)
            setInitValues(initialValues)
        }
    })

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IVatDetails>({
        enableReinitialize: true,
        initialValues: initValues,
        validationSchema: accessGroupSchema,
        onSubmit: (values) => {
            setLoading(true)
            if (chosenListItem && chosenListItem.id) {
                vatRateEditMutation.mutate({
                    vatId:chosenListItem.id,
                    data: {rate: values.rate},
                    accessToken,
                })
            } else {
                vatRateCreateMutation.mutate({accessToken, data: {
                    rate: +values.rate
                    }})
            }
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div id='access_group_form' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6 d-flex flex-column'>
                            <div className='d-flex flex-column col-12 col-sm-6'>
                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Stawka Vat</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        min={0}
                                        max={100}
                                        type='number'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Stawka Vat'
                                        {...formik.getFieldProps('rate')}
                                    />
                                    {formik.touched.rate && formik.errors.rate && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.rate}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && !chosenListItem && 'Dodaj'}
                            {!loading && chosenListItem && 'Zapisz'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                  Dodawanie...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                            )}
                        </button>
                    </div>
                </form>
                {showSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>{showSuccess}!</p>}
            </div>
        </div>
    )
}

export {VatCreateModalForm}
