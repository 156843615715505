/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import { VatTable } from './VatTable';
import {ClientTable} from "../clientList/ClientTable";


const VatWrapper: FC = () => {

    return (
        <>
            <PageTitle>Stawki VAT</PageTitle>
            <VatTable className='card-xxl-stretch mb-5 mb-xl-8'/>
        </>
    )
}

export {VatWrapper}
